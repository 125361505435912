import { DomPortalOutlet } from '@angular/cdk/portal';
import { AfterViewChecked, ApplicationRef, Component, ComponentFactoryResolver, HostListener, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Moment, utc } from 'moment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import { CapacityMetricItem, GetMetricsMonthlyParam, GetMetricsParameters, GetMetricsResponse, Interval, Queue } from 'api/types';
import { PageBodyComponent } from 'components/common/page-body/page-body.component';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_15MIN, INTERVAL_1DAY } from 'constants/intervals';
import { MetricsService } from 'services/api/metrics.service';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { AppointmentsInlineCapacityService } from 'services/appointments-inline-capacity.service';
import { PageStatusService } from 'services/status/page-status.service';
import { Timescale } from 'types/AppointmentFilters';
import { ERROR, LOADING, SUCCESS } from 'types/RequestStatus';
import { Timezone } from 'types/Timezone';
import { ComponentCanDeactivate } from '../../guards/unsaved-changes.guard';
import { AlertResolveFiltersService } from 'services/alert-resolve-filters.service';
import { ActivatedRoute } from '@angular/router';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';



export let muVariable: any
@Component({
  selector: 'app-alerts-resolve-page',
  templateUrl: './alerts-resolve-page.component.html',
  styleUrls: ['./alerts-resolve-page.component.scss']
})
export class AlertsResolvePageComponent implements OnInit, OnDestroy, ComponentCanDeactivate, AfterViewChecked {
  /**
 * Page body reference to reset loading state if queues were to fail
 */
  enableBack = true;
  isAlertHoursValue: any
  rsponsebudata: any
  isCalendarDetails!: any
  getParams: any
  @ViewChild(PageBodyComponent) public pageBodyComponent?: PageBodyComponent;

  /**
   * Selected timezone from within the appointment filters
   */
  public timezone: Timezone | null = null;

  /**
   * Metric items used to display capacity & registration details for the dates in context
   */
  public metricItems: CapacityMetricItem[] = [];

  alertSpecificData: any

  /**
   * Selected interval of the data shown
   */
  public interval: Interval = INTERVAL_15MIN;

  /**
   * The current month the user is viewing, if in month view
   */
  public selectedMonth: Moment = utc();

  /**
   * Flag to display the inline error message.
   */
  public showNetworkError = false;

  /**
   * Internal variable that tracks the page loading state
   */
  public pageLoading = false;

  /**
   * Fetches all metrics needed for the page.
   * Calls are made based on the currently selected filters.
   * Trigger a new data refresh with .next().
   * Cancels previous calls.
   */
  public refreshMetricsData$ = new Subject<void>();

  /**
   * Whether there are any outstanding edits
   */
  private unsavedChanges = false;

  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  /**
   * The current parameters used for the `getMetrics` API
   */
  private getMetricParams?: GetMetricsMonthlyParam;

  /**
   * Reference to the outlet created by adding the UnsavedChangesBanner to the portal
   */
  private unsavedChangesOutlet?: DomPortalOutlet;

  public constructor(
    private getMetricsService: MetricsService,
    private route: ActivatedRoute,
    private filterService: AlertResolveFiltersService,
    private inlineCapacityService: AppointmentsInlineCapacityService,
    private pageStatusService: PageStatusService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private addAlertConsumptionCreate: AddAlertPoolDataService,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {
    pageStatusService.status$.pipe(
      takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.pageLoading = status === LOADING;
      });
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  public canDeactivate(): Observable<boolean> | boolean {
    return !this.unsavedChanges;
  }

  /**
   * Set up subscriptions to get page data
   * Either when params (filters) change or inline capacity is edited
   */


  public ngOnInit(): void {
    this.route.queryParams.subscribe((ele: any) => {
      const paresdParam = JSON.parse(ele.data);
      this.isCalendarDetails = paresdParam;
      // resolve(paresdParam);

    })

    this.addAlertConsumptionCreate.refreshNeeded$.subscribe(() => {
      this.filterService.params$
        .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
        .subscribe((updated) => {
          this.getMetricParams = updated;
          this.refreshMetricsData$.next();
        });
    });
    this.filterService.params$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((updated) => {
        this.getMetricParams = updated;
        this.refreshMetricsData$.next();
      });
    this.inlineCapacityService.editSubmissionStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.showNetworkError = false;
        switch (status) {
          case SUCCESS:
            this.refreshMetricsData$.next();
            break;
          case ERROR:
            this.showNetworkError = true;
            break;
        }
      });

    // Use the number of edited controls to determine whether there are outstanding changes
    this.inlineCapacityService.numberOfEditedControls$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((num) => {
        this.unsavedChanges = num > 0;
      });

    // Update metrics
    this.refreshMetricsData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        const params = this.paramsForTimescale();
        this.getParams = params;

        // selected queue id is needed for metrics call
        if (!params) {
          return EMPTY;
        }

        this.pageStatusService.loading(() => {
          // Reset loading count so full page loader is shown
          this.pageBodyComponent?.resetLoadingCount();
          this.refreshMetricsData$.next();
        });

        // Perform the call!


        return this.getMetricsService.getMetric(params, this.isCalendarDetails.key.id);
      })).subscribe((res) => {
        this.getMetricsSuccess(res);
      }, () => {
        this.pageStatusService.error();
      })
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.unsavedChangesOutlet?.detach();
  }

  /**
   * After every view update, check if unsavedChangesPortal should be added to the page
   */
  public ngAfterViewChecked(): void {
    const portalDestination = document.querySelector('#unsaved-changes-portal');

    /*
     * Add unsaved changes banner should be added when:
     * 1. It doesn't already exist
     * 2. timescale is daily
     * 3. portal destination can be found
     */
    const hasAttached = this.unsavedChangesOutlet?.hasAttached();
    const isDaily = this.timescale() === Timescale.daily;
    if (!hasAttached && isDaily && portalDestination) {
      this.unsavedChangesOutlet = new DomPortalOutlet(
        portalDestination,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
      );

      // this.unsavedChangesOutlet.attach(this.unsavedChangesPortal);
    }
    else if (hasAttached && !isDaily) {
      // If unsaved changes banner is attached and timescale is no longer daily, detach it
      this.unsavedChangesOutlet?.detach();
    }
  }


  /**
   * Determine the current timescale based on numberOfDays
   *
   * @returns 'daily' | 'weekly' | 'monthly'
   */
  public timescale(): Timescale {
    const numberOfDays = this.getMetricParams?.numberOfDays || 0;
    if (numberOfDays === 7) {
      return Timescale.weekly;
    } else if (numberOfDays > 7) {
      return Timescale.monthly;
    }
    return Timescale.daily;
  }

  /**
   * Success handler for getMetrics API
   */
  private getMetricsSuccess(res: any): void {

    let data = {
      totalNetQueueAvail: res.totalNetQueueAvail,
      totalPoolAva: res.totalPoolAva,
      totalPoolRegistrations: res.totalPoolRegistrations,
      date: res.startDate,
      endDate: res.endDate,
      id: res.id,
      name: res.poolName
    }
    // Combine items from all days
    this.metricItems = res.alertDayDetails.items;
    this.alertSpecificData = data;

    this.pageStatusService.success();


  }

  /**
   * Returns the params for the currently selected timescale
   */
  private paramsForTimescale(): GetMetricsMonthlyParam | null {
    if (!this.getMetricParams || this.getMetricParams.numberOfDays === 0) {
      return null;
    }

    switch (this.timescale()) {
      case Timescale.monthly:
        return this.getMonthlyParams(this.getMetricParams);
      default:
        return this.getMetricParams; // Daily uses the parameters straight from the filterService
    }
  }

  /**
   * Calculates the API parameters for `getMetrics` for a given month.
   *
   * To fill the full calendar view, days outside of the current month could be included.
   */
  private getMonthlyParams(params: GetMetricsMonthlyParam): GetMetricsMonthlyParam {
    const monthStart = utc(params.startDate).startOf('month');
    const monthEnd = utc(params.startDate).endOf('month');

    this.selectedMonth = monthStart.clone();

    // Figuring out how many days we need before and after the month to fill all calendar cells
    const daysBeforeNeeded = monthStart.day();
    const daysAfterNeeded = 6 - monthEnd.day();

    // Adding and subtracting appropriate amount of days from month start and end
    const newStart = monthStart.subtract(daysBeforeNeeded, 'days');


    const newEnd = monthEnd.add(daysAfterNeeded, 'days');

    // Getting correct number of days to fill the calendar
    const numDays = newEnd.diff(newStart, 'days') + 1;
    const startDate = newStart.format(EXCHANGE_FORMAT);



    return {
      ...params,
      startDate: startDate,
      numberOfDays: numDays,
      hoursFlag: this.isAlertHoursValue
    };
  }

  getselectedValue(event: any) {
    this.isAlertHoursValue = event;
    this.addAlertConsumptionCreate.triggerRefresh();
  }
}
