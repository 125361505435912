<div class="wrapper">
  <div class="month-header">
    <p>
      <b>{{this.getCalendarDetails?.key?.name}}</b>
      <br>
      <small>{{"title.net/queue" | translate | async }}</small>
      <b><small>{{ this.monthlyMetricItems?.netQueueAva }}</small></b>
    </p>
    <p>
      {{"title.pool/Availablility" | translate | async }}
      <b>{{ this.monthlyMetricItems?.poolAvail }}</b>
    </p>
    <p>
      {{"title.pool/utilization" | translate | async }}
      <b>{{this.monthlyMetricItems?.utilization }}</b>
    </p>
    <app-filter-container style="position:relative;left:110px;top:-8px;">
      <app-alert-resolve-filters [setDatePickerValues]="setDatePickerValues"
        [alertSpecificData]="alertSpecificData"></app-alert-resolve-filters>
    </app-filter-container>
    <div style="position: absolute; right: 10px; top: 27px;">
      <mat-radio-group [(ngModel)]="getAlertTimeRange" (change)="getSelectedTimeRange()">
        <span class="Alert_hours" style="margin-right: 14px;">
          <mat-radio-button class="example-margin" value="Alerthours">{{"label.alert/hours" | translate | async
            }}</mat-radio-button>
        </span>
        <span>
          <mat-radio-button class="example-margin" value="Allhours">{{ "label.all/hours" | translate | async
            }}</mat-radio-button>
        </span>
      </mat-radio-group>
    </div>
  </div>
  <div class="week-and-day-wrapper">
    <div class="week-data">
      <div class="box-above-week-data" aria-hidden="true"></div>
      <app-alert-resolve-calendar-item *ngFor="let item of weeklyMetricItems" [isWeek]="true" [metricItem]="item" [id]="getCalendarDetails"
        [alertSpecificData]="alertSpecificData"></app-alert-resolve-calendar-item>
    </div>
    <div class="daily-grid">
      <p class="week-day-header">{{ "label.dayOfWeek.sunday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.monday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.tuesday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.wednesday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.thursday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.friday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.saturday" | translate | async }}</p>
      <app-alert-resolve-calendar-item *ngFor="let item of dailyMetricItems" [metricItem]="item" [id]="getCalendarDetails"
        [alertSpecificData]="alertSpecificData"></app-alert-resolve-calendar-item>
    </div>
  </div>
</div>