<button mat-button vueTableMenuOpen vueOverlayOpen class="menu-trigger" [matMenuTriggerFor]="poolConsumptionMenu">
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #poolConsumptionMenu="matMenu" class="pool-consumption-menu" xPosition="before">
  <a mat-menu-item routerLink="../poolconsumption/{{poolconsumption.id}}" routerLinkActive="active">
    View
  </a>
  <a mat-menu-item (click)="showCopyModal()">
    Copy
  </a>
  <a mat-menu-item *ngIf="poolconsumption.status === 'active' && !isPoolConsumptionEnds()" (click)="showConfirmationModal('deactivate')">
    Deactivate
  </a>
  <a mat-menu-item *ngIf="poolconsumption.status === 'inactive'" (click)="updateStatusPoolConsumption('activate')">
    Activate
  </a>
  <a mat-menu-item *ngIf="!isPoolConsumptionEnds()" (click)="showConfirmationModal('delete')">
    Delete
  </a>
</mat-menu>
<vue-toast icon="success">
  <span toast-body>{{toastMessage}}</span>
  <a *ngIf="showViewInToast" (click)="viewPoolConsumption()" toast-action>
    {{ 'button.viewPoolConsumption' | translate | async }}
  </a>
</vue-toast>
<app-copy-pool-consumption-drawer [patchDataStatus]="patchDataStatus" ></app-copy-pool-consumption-drawer>

<!-- [patchDataStatus]="patchDataStatus" -->