import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertRunFrequencyItems, AlertThresholdItems } from 'api/types/endpoints/getPoolConsumption';
import { Observable } from 'rxjs';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Component for removing an individual template
 */
@Component({
  selector: 'app-copy-modal',
  templateUrl: './copy-modal.component.html'
})
export class CopyModalComponent {
  poolName: boolean = false;
  dateRange: boolean = false;
  timeRange: boolean = false;
  threshold: boolean = false;
  runFrequency: boolean = false;

  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: CopyModalInputs,
    private dialogRef: MatDialogRef<CopyModalComponent>
  ) { }

  public closeDialog(): void {
    this.dialogRef.close(null);
  }

  public action(): void {
    this.dialogRef.close(
      {
        pool: this.poolName,
        dateRange: this.dateRange,
        timeRange: this.timeRange,
        threshold: this.threshold,
        runFrequency: this.runFrequency
      }
    );
  }

  setCritical(code: string) {
    switch (code) {
      case "0":
        return 'No'
      case "1":
        return 'Yes'
      case "3":
      default:
        return '-'
    }
  }

  formatRunDaysOfWeek(data: any) {
    var daysOfWeek: any[] = [];
    data.forEach((item : any) => {
      daysOfWeek.push(item.split("sunday").join("sun").split("monday").join("mo").split("tuesday").join("tu").split("wednesday").join("we").split("thursday").join("th").split("friday").join("fr").split("saturday").join("sa"));
    });
    return daysOfWeek;
  }
}

export const copyModalConfig = {
  ...VUE_DIALOG_CONFIG,
  width: '23rem',
};

export interface CopyModalInputs {

  /**
   * Title shown on top of dialog
   */
  title: Observable<string>;

  poolName: string;
  formattedPoolStartDate: string;
  formattedPoolEndDate: string;
  poolStartTime: string;
  poolEndTime: string;
  threshold: AlertThresholdItems[];
  runFrequency: AlertRunFrequencyItems[];
}
