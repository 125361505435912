<div class="wrapper">
  <button
    mat-button
    class="label-button"
    (click)="openDatePicker()"
    [ngClass]="{'customStyleFromCapacityUpload': customStyle} || timescale || ''"
    aria-haspopup="dialog"
    aria-describedby="datepicker-description"
  >
    <span *ngIf="!getDatePickerValues" aria-live="assertive">{{ label() }} - {{ endDatelabel() }}</span>
    <span *ngIf="getDatePickerValues" aria-live="assertive">{{ label() }} </span>
    <span id="datepicker-description" class="visually-hidden" aria-hidden="true">
      <ng-container [ngSwitch]="timescale">
        <ng-container *ngSwitchCase="'monthly'">
          {{ 'label.aria.openMonthlyDatepicker' | translate | async }}
        </ng-container>
        <ng-container *ngSwitchCase="'outagesmonthly'">
          {{ 'label.aria.openMonthlyDatepicker' | translate | async }}
        </ng-container>
        <ng-container *ngSwitchCase="'outagesyearly'">
          {{ 'label.aria.openYearlyDatepicker' | translate | async }}
        </ng-container>
        <ng-container *ngSwitchCase="'weekly'">
          {{ 'label.aria.openWeeklyDatepicker' | translate | async }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ 'label.aria.openDailyDatepicker' | translate | async }}
        </ng-container>
      </ng-container>
    </span>
  </button>

  <!-- Datepickers -->
  <ng-container [ngSwitch]="timescale">
    <app-monthly-datepicker
      *ngSwitchCase="'monthly'"
      [startDate]="startDate"
      (monthChange)="manualDateChange($event)"
      [alertSpecificData]="alertSpecificData"
    ></app-monthly-datepicker>
    <app-monthly-datepicker
      *ngSwitchCase="'outagesmonthly'"
      [startDate]="startDate"
      (monthChange)="manualDateChange($event)"
    ></app-monthly-datepicker>
    <app-yearly-datepicker
    *ngSwitchCase="'outagesyearly'"
    [startDate]="startDate"
    (monthChange)="manualDateChange($event)"
    ></app-yearly-datepicker>
    <app-weekly-datepicker
      *ngSwitchCase="'weekly'"
      [startDate]="startDate"
      (weekChange)="manualDateChange($event)"
    ></app-weekly-datepicker>
    <app-daily-datepicker
      *ngSwitchDefault
      [startDate]="startDate"
      (dayChange)="manualDateChange($event)"
    ></app-daily-datepicker>
  </ng-container>
</div>

