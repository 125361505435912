<div class="table-container" #tableContainer id="tableContainer" [formGroup]="inlineCapacityForm" (scroll)="onScrollTable($event)">
  <ng-container formArrayName="capacities">

    <table mat-table [dataSource]="dataSource">
      <caption class="visually-hidden">
        {{ 'table.caption.dailyAppointments' | translate | async }}
      </caption>

      <!-- Generate columns, first one is sticky -->
      <ng-container *ngFor="let id of columnIds, let i = index, let element" [matColumnDef]=id [sticky]="i === 0">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="getCellStyle(i)">
          <app-table-header-cell
            label="{{ columnLabels[i] | async }}"
            [secondaryLabel]="secondaryColumnLabels ? secondaryColumnLabels[i]: ''"
            [alignLeft]="i === 0"
          ></app-table-header-cell>
          <!-- <app-synched-scroll-button *ngIf="i === 0" direction="backward"></app-synched-scroll-button> -->
        </th>

        <!-- First column contains _row_ headers -->
        <ng-container *ngIf="i === 0">
          <td
            mat-cell
            role="rowheader"
            *matCellDef="let element"
            class="row-header"
          >
            <div [ngClass]="cellClassName(element.key, element[id], i)">
              {{element[id]}}
            </div>
          </td>
        </ng-container>

        <!-- Regular cells -->
        <ng-container *ngIf="i > 0">
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="enableCapacityEdit(i, element);else readonlyContent">
              <vue-numeric-input
                [formControlName]="getControlName(i)"
                [appBackgroundFade]="showBackgroundFade(i)"
                [useFirstChild]="true"
                [borderRadius]="true"
                [maxLength]="5"
              ></vue-numeric-input>
            </ng-container>
            <ng-template #readonlyContent>
              <div [ngClass]="cellClassName(element.key, element[id], i)">
                {{element[id]}}
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- Cell content -->
        <ng-template #cellContent let-element>
          <ng-container *ngIf="enableCapacityEdit(i, element);else readonlyContent">
            <vue-numeric-input
              [formControlName]="getControlName(i)"
              [appBackgroundFade]="showBackgroundFade(i)"
              [useFirstChild]="true"
              [borderRadius]="true"
              [maxLength]="5"
            ></vue-numeric-input>
          </ng-container>
          <ng-template #readonlyContent>
            <div [ngClass]="cellClassName(element.key, element[id], i)">
              {{element[id]}}
            </div>
          </ng-template>
        </ng-template>

      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnIds; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnIds;"></tr>
    </table>
  </ng-container>
</div>
<!-- <app-synched-scroll-button direction="forward"></app-synched-scroll-button> -->
