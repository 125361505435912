import { formatNumber } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { utc } from 'moment';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilKeyChanged, take, takeUntil } from 'rxjs/operators';
import { CapacityMetricItem } from 'api/types';
import { calculateUtilizationPercentage } from 'components/common/pools/utils/calculate-utilization-percentage';
import { TimezoneDisplayPipe } from 'pipes/timezone-display.pipe';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { AppointmentsInlineCapacityService } from 'services/appointments-inline-capacity.service';
import { SynchedScrollService } from 'services/synched-scroll.service';
import { NgStyleExpression } from 'types/NgStyleExpression';
import prop from 'types/prop';
import { Timezone } from 'types/Timezone';
import { formatTime } from 'utils/format-time';

/**
 * Object of key value pairs
 * First Key/Value pair is row ID and display value
 * The rest are:
 * Key is UTC timestamp
 * Value is value respective to the row
 */
interface TableRow {
  [key: string]: string | null;
}

interface FormattedItem extends CapacityMetricItem {
  columnId: string;
  formattedUtcDate: string;
  formattedLocalDate: string;
  utilization: string;
  availability: number;
  emphasized?: boolean;
}

/**
 * Appointment Capacity Table with custom styling, fixed header and first column, based on mat-table
 * ChangeDetection set to OnPush to help manage Angular re-rendering
 */
@Component({
  selector: 'app-appointments-table',
  templateUrl: './appointments-table.component.html',
  styleUrls: [ './appointments-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentsTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /**
   * An array of metrics from the API
   */
  @Input() public metricItems: CapacityMetricItem[] = [];

  /**
   * The timezone selected by the user, if set
   */
  @Input() public timezone?: Timezone | null;

  /**
   * Fixed columns widths, optional
   */
  @Input() public columnWidthRems?: number;

  /**
   * First column width, optional
   */
  @Input() public firstColumnWidthRems?: number;

  @Input() public valueFromDailyChart!: number;

  /**
   * Reference to table container
   * Used to register with scroll service
   */
  @ViewChild('tableContainer') private tableContainer?: ElementRef;

  /**
   * Array of column labels
   */
  public columnLabels: Observable<string>[] = [];

  /**
   * Array of secondary column labels, representing timezone offset
   * null if timezone is not a selected filter
   */
  public secondaryColumnLabels: string[] | null = null;

  /**
   * Ids of individual columns
   */
  public columnIds: string[] = [];

  /**
   * Array of each row of data, passed to table component
   */
  public dataSource: TableRow[] = [];

  /**
   * Restricts editing capacities when the date selected in the filter is in the past
   */
  public canEditCapacity = true;

  /**
   * List of capacity items, formatted for use in the table
   */
  private items: FormattedItem[] = [];

  /**
   * Terminate all subscriptions
   */
  private destroyed$ = new Subject();

  /**
   * Array of indices for those indexes that have changed
   * Used to show appBackgroundFade on the capacities that have changed
   */
  private editedIndices: number[] = [];

  /**
   * The localization keys to look up.
   */
  private translationKeys: TranslationKey[] = [
    'title.capacity',
    'title.registrations',
    'title.utilization',
    'title.availability',
    'label.utc.timezone',
  ]

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  scrollSubscription!: Subscription;

  public constructor(
    private scrollService: SynchedScrollService,
    private inlineCapacityService: AppointmentsInlineCapacityService,
    private cdr: ChangeDetectorRef,
    private translatePipe: TranslatePipe,
    private appointmentsFilters: AppointmentsFiltersService,
    private timezoneDisplayPipe: TimezoneDisplayPipe,
  ) {}

  /**
   * Getter method for inlineCapacityEditForm
   * Shorter reference in templates
   *
   * @returns form the current form state
   */
  public get inlineCapacityForm(): UntypedFormGroup {
    return this.inlineCapacityService.inlineCapacityEditForm;
  }

  /**
   * Gets style object for cell based on the column
   *
   * @param colIndex index of column
   * @returns Style object or null
   */
  public getCellStyle(colIndex: number): NgStyleExpression {
    if (colIndex === 0) {
      return this.firstColumnWidthRems ? { 'min-width.rem': this.firstColumnWidthRems } : null;
    }
    return this.columnWidthRems ? { 'min-width.rem': this.columnWidthRems } : null;
  }

  /**
   * Build table on init and subscribe to updated capacities
   */
  public ngOnInit(): void {
    this.scrollSubscription = this.scrollService.scrollfromChart$.subscribe((value) => {
      if(this.tableContainer){
        this.tableContainer.nativeElement.scrollLeft = value;
      }
    });

    this.buildTable();

    this.inlineCapacityService.updatedCapacityIndices$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((editedIndices) => {
        this.editedIndices = editedIndices;
        this.updateControlValidity([]);

        /**
         * Clear editedIndices after timer
         * Forcing all instances of appBackgroundFade get reset
         */
        timer(60).pipe(take(1)).subscribe(() => {
          this.editedIndices = [];
        });
      });

    this.inlineCapacityService.erroredCapacityIndices$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((errorIndices) => {
        this.updateControlValidity(errorIndices);
      });

    // Load the localization translations
    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((result) => {
        this.translations = result;
      });

    // Store when filters are showing a date that is in the past
    this.appointmentsFilters.params$
      .pipe(takeUntil(this.destroyed$), distinctUntilKeyChanged('startDate'))
      .subscribe((params) => {
        this.canEditCapacity = utc(params.startDate).isSameOrAfter(utc().startOf('day'));
      });
  }

  /**
   * Register with scroll service
   */
  public ngAfterViewInit(): void {
    this.scrollService.registerScrollContainer(this.tableContainer?.nativeElement);
  }

  /**
   * Unregister with scroll service & terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.scrollService.unregisterAll();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.scrollSubscription.unsubscribe();
  }

  /**
   * Rebuild table when changes occur
   */
  public ngOnChanges(): void {
    this.buildTable();
  }

  /**
   * Determines if a capacity has updated and show background fade effect
   * Similar to getControlName, subtract 1 from index to account for column offset
   *
   * @param index current column index
   * @returns true if background fade should be shown
   */
  public showBackgroundFade(index: number): boolean {
    return this.editedIndices.includes(index - 1);
  }

  /**
   * Capacity Controls Array starts at zero while row data array contains the row label first
   * Subtract 1 to align the column with the correct control
   *
   * @param index column index
   * @returns index of capacity control
   */
  public getControlName(index: number): string {
    return `${index - 1}`;
  }

  /**
   * Determines if numeric inputs should be shown for the capacity row
   */
  public enableCapacityEdit(columnIndex: number, row: TableRow): boolean {
    return columnIndex !== 0 && row.key === 'capacity' && this.canEditCapacity;
  }

  /**
   * Returns ngClass object defining the class names for each individual table cell
   */
  public cellClassName<K extends keyof FormattedItem>(
    key: K, value: FormattedItem[K], index: number
  ): Record<string, boolean> {
    return {
      'cell-content': index !== 0,
      emphasized: key === 'utilization',
      warn: key === 'availability' && Number(value) < 0
    };
  }

  /**
   * Builds all of the table details & configures scroll service
   * If there is not metric times, no table properties change
   * Using timer(0) to allow for other UI processing while chart details are constructed
   */
  private buildTable(): void {
    if (!this.metricItems.length) {
      return;
    }
    this.dataSource = [];
    timer(0).pipe(take(1)).subscribe(() => {
      this.formatItems(this.metricItems);
      this.columnLabels = this.getColumnLabels();
      this.secondaryColumnLabels = this.getSecondaryColumnLabels();
      this.dataSource = this.tableData();
      this.columnIds = [ 'category', ...this.items.map<string>((item) => item.columnId) ];

      this.inlineCapacityService.constructForm(this.getRow('capacity', this.translations[ 'title.capacity' ]));
      this.scrollService.configure(this.columnWidthRems || 0, this.metricItems.length);

      // Set change detection for all updated properties
      this.cdr.detectChanges();
    });
  }

  /**
   * Update controls to show error outline if submission was unsuccessful
   *
   * @param errorIndices indices of the controls that should show error
   */
  private updateControlValidity(errorIndices: number[]): void {
    this.inlineCapacityService.capacityControls.controls.forEach((control, i) => {
      if (errorIndices.includes(i)) {
        control.setValidators(() => {
          return { error: i };
        });
      } else {
        control.setValidators(null);
      }
      control.updateValueAndValidity();
    });
  }

  /**
   * Get the primary column labels for the table
   *
   * Convert all strings to observables to allow for dynamic timezone label
   *
   * @returns array of column labels
   */
  private getColumnLabels(): Observable<string>[] {
    return (this.timezone) ? [
      this.timezoneDisplayPipe.transform(this.timezone, 'condensed'),
      ...this.items.map((i) => of(i.formattedLocalDate))
    ] : [
      of(this.translations[ 'label.utc.timezone' ]),
      ...this.items.map((i) => of(i.formattedUtcDate))
    ];
  }

  /**
   * If relevant, get the secondary column labels for the table
   *
   * @returns timezone labels if timezone is defined, null if it is not
   */
  private getSecondaryColumnLabels(): string[] | null {
    if (this.timezone) {
      return [
        this.translations[ 'label.utc.timezone' ],
        ...this.items.map((i) => i.formattedUtcDate)
      ];
    }

    return null;
  }

  /**
   * Get the array of row data to drive the table
   *
   * @returns rows an array of rows
   */
  private tableData(): TableRow[] {
    return [
      this.getRow('utilization', this.translations[ 'title.utilization' ]),
      this.getRow('capacity', this.translations[ 'title.capacity' ]),
      this.getRow('registrations', this.translations[ 'title.registrations' ]),
      this.getRow('availability', this.translations[ 'title.availability' ])
    ];
  }

  /**
   * Get one row of data for a given category
   *
   * @param categoryName The name of the row's category
   * @param label the category label for the row
   * @returns One row of data
   */
  private getRow(categoryName: keyof FormattedItem, label: string): TableRow {
    return this.items.reduce<TableRow>((row, item) => {
      const val = prop(item, categoryName);
      row[ item.columnId ] = val !== null ? String(val) : val;
      return row;
    }, { key: categoryName, category: label });
  }

  /**
   * Add columnId, utilization calculation and formatted dates to plain CapacityMetricItems
   *
   * @param metricItems plain CapacityMetricItems
   */
  private formatItems(metricItems: CapacityMetricItem[]): void {
    this.items = metricItems.map((item) => {
      const columnId = item.timestamp;
      const formattedUtcDate = formatTime(item.timestamp);
      const formattedLocalDate = formatTime(item.timestamp, this.timezone);
      const utilizationPercentage = calculateUtilizationPercentage(item.registrations, item.capacity || 0);
      const utilization = formatNumber(utilizationPercentage, 'en-US', '1.2-2') + '%';
      const availability = (item.capacity || 0) - item.registrations;

      return { ...item, columnId, formattedUtcDate, formattedLocalDate, utilization, availability };
    });
  }

  onScrollTable(event: Event){
    this.scrollService.setApptTableScroll((event.target as HTMLElement).scrollLeft)
  }
}
