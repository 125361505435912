import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetExamsParameters, GetExamsResponse } from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { PoolsMicroservice } from './microservices/pools.microservice';

/**
 * API service for getting exams
 */
@Injectable({
  providedIn: 'root'
})
export class ExamsService {
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetExamsParameters = {
    clientIds: [],
    limit: 25
  }

  public constructor(private api: PoolsMicroservice) { }

  /**
   * Calls GetExams API
   *
   * @param params optional params for get exams call
   * @returns GetExamsResponse Observable
   */
  public getExams(params?: Partial<GetExamsParameters>): Observable<GetExamsResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params
    });
    const path = `exams${queryString}`;

    return this.api.get<GetExamsResponse>(path);
  }
}
