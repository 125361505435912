import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { AlertEditPcToastComponent } from 'components/common/alerts/alert-edit-pc-toast/alert-edit-pc-toast.component';
import { AddPoolState, PoolStep } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { VueDrawerComponent, VueDrawerConfig } from 'vue/components/vue-drawer/vue-drawer.component';

@Component({
  selector: 'app-edit-pool-consumption-drawer',
  templateUrl: './edit-pool-consumption-drawer.component.html',
  styleUrls: ['./edit-pool-consumption-drawer.component.scss']
})
export class EditPoolConsumptionDrawerComponent implements OnInit {
  @ViewChild(AlertEditPcToastComponent) public editPoolToast!: AlertEditPcToastComponent;
  @Output() public success = new EventEmitter<any>();
  addPoolForm!: FormGroup
  getDataForCreateAlert:any=null
  private error?: DisplayableServerError | null;
  public status: RequestStatus = 'initial';
  @ViewChild('drawer') public drawer!: VueDrawerComponent;
  public step: PoolStep = 'form'
  public drawerConfig: VueDrawerConfig = {
    disableClose: true,
  }
  childupdatedValuestatus: any=false;
  getRecordId:any
  private destroyed$ = new Subject();
  errorMessage: any;
  disabledAtresponsegetting:any=false;
  toastMessage: any;
  

  constructor(private addPoolState: AddAlertPoolDataService,
    private drawerStatusService: DrawerStatusService,
    private unsavedChangesDialogService: UnsavedChangesDialogService,
    private addPoolStates: AddPoolState,
   private addAlertConsumptionCreate: AddAlertPoolDataService,
   private router:ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.router.url.subscribe((ele:any)=>{
      this.getRecordId=ele[3].path
    });
     this.drawerStatusService.status$
     .pipe(takeUntil(this.destroyed$))
     .subscribe((status) => {
       this.status = status;
     });

     this.addAlertConsumptionCreate.errorobjects$.subscribe((ele:any)=>{
      if(ele){
        this.status='error';
      this.error={ message: ` Time:${ele.runTime} - value ${ele.Element}`, title: ` RunFrequency Duplicate are not allowed` || '' };
      this.drawerStatusService.error();
      }
      
    })
  }
  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.addPoolStates.displayableServerError || this.error;
  }
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public closeDrawerbtn(): void {
   if( this.childupdatedValuestatus){
      this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
        if (choseToLeave) {
         this. getDataForCreateAlert=null;
          this.closeDrawer();
        }
      });
   } else{
    this.closeDrawer();
   } 
  }

  public closeDrawer(): void {
    this.drawer.close();
    this.reset();
  }

  public reset(): void {
    this.addPoolState.resetState();
    this.drawerStatusService.reset();
  }
  
  getdatafromchild(event:any){
    this.getDataForCreateAlert=event;
  }

  SavePool(){
    this.drawerStatusService.loading();
    this.errorMessage = null;
    let checkValidationerror=this.getfilterValue(this.getDataForCreateAlert);
    if(checkValidationerror.length == 0){
    this.addAlertConsumptionCreate.editAlertConsumption(this.getDataForCreateAlert,this.getRecordId).pipe(take(1)).subscribe((response:any) => {
        this.closeDrawer();
        this.error = null;
        this.getupdateddata()
        this.drawerStatusService.success();
    }, (error: unknown) => {
      this.disabledAtresponsegetting=false;
      this.error = getDisplayableServerError(error);
      this.drawerStatusService.error();
    });
  }
 }

  getStatusofchild(event:any){
    this.childupdatedValuestatus=event;
  }

  getrunfrequencyupdated(value: any ) {
    const idMap = new Map();
    const duplicates:any = []
    // const idSet=new Set();
    for (const obj of value) {
      const { runTime, runDaysOfWeek,isCritical,runPriority } = obj;
      if (idMap.has(runTime)) {
        const existingArray = idMap.get(runTime);
        if(existingArray.isCritical === isCritical &&  existingArray.runPriority === runPriority){
          const currentDuplicates=[]
        for (const element of runDaysOfWeek) {
          if ( existingArray.runDaysOfWeek.indexOf(element) !== -1) {
            currentDuplicates.push(element)
             console.log(`Duplicate element ${element} found for ID ${runTime}` );
            this.addAlertConsumptionCreate.getupdatedlist({ 'Element': element, 'runTime': runTime })
          }
        }
        if (currentDuplicates.length > 0) {
            duplicates.push({runTime,isCritical,runPriority,duplicates})
        } 
      existingArray.runDaysOfWeek=[...new Set([...existingArray.runDaysOfWeek, ...runDaysOfWeek ])]
    }
    // else{
         
           
    //    }

      }else {
        idMap.set(runTime, {runDaysOfWeek,isCritical,runPriority})
      }
    }

    return duplicates;
     
  }
   getfilterValue(filter:any){
    let value: any = []
    filter.run_Frequency.FormGroup.Time.forEach((ele: any) => {
      let arraylist: any = [];
      ele.weekDays.forEach((data: any) => {
        if (data.checked == true)
          arraylist.push(data.value)
      })
      value.push(
        {
          "runTime": ele.Time,
          "runPriority": ele.Priority,
          "isCritical": ele.Critical,
          "daysFlag": arraylist.length == 7 ? "daily" : "weekly" ,
          "runDaysOfWeek": arraylist
        })
       })
      const result =this.getrunfrequencyupdated(value)
      return result;

   }
   getupdateddata(){
      this.toastMessage = this.getDataForCreateAlert.getDatafromAlert;
      this.editPoolToast.openToast(this.toastMessage);
      this.addAlertConsumptionCreate.triggerRefresh();
  
   }

}
