import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChipContent } from 'components/common/dismissible-chip/dismissible-chip.component';

/**
 *  Component for displaying currently selected filters.  Allows filters to be dismissed.
 */
@Component({
  selector: 'app-filter-by',
  templateUrl: './filter-by.component.html',
  styleUrls: [ './filter-by.component.scss' ]
})
export class FilterByComponent<ChipType> {
  /**
   * Current filters to display
   */
  @Input() public filters: ChipContent<ChipType>[] = [];

  /**
   * Emits when a chip is to be removed
   */
  @Output() public filterRemoved = new EventEmitter<ChipType>()
}
