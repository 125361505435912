<vue-page-header appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.alerts' | translate | async }}" navSection="{{ 'title.alerts' | translate | async }}">
  <app-filter-container>
    <app-alerts-filters></app-alerts-filters>
    <div style="flex: none;">
      <vue-basic-button (clicked)="resetFilter()">{{
        'title.reset' | translate | async
        }}</vue-basic-button>
      <vue-flat-button (clicked)="searchAlerts()">{{
        'title.search' | translate | async
        }}</vue-flat-button>
    </div>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
    [message]="displayableServerError?.message">
  </app-error-message>
  <vue-section>
    <app-alerts-table></app-alerts-table>
  </vue-section>
</app-page-body>
<vue-toast icon="success">
  <span toast-body>{{ toastMessage }}</span>
</vue-toast>