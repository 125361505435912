<div [ngClass]="getDatePickerValues == true ? {
    'date-selection-filter': true,
    'group-position-first': groupPosition === 'first',
    'group-position-last': groupPosition === 'last',
    'customStyleFromCapacityUpload': customStyle
  } : {
    'date-selection-resolve-filter':true,
    'group-position-first': groupPosition === 'first',
    'group-position-last': groupPosition === 'last',
    'customStyleFromCapacityUpload': customStyle
  }">
  <button mat-button type="button" [attr.aria-label]="backButtonTranslationKey | translate | async"
    (click)="backwardClicked()">
    <vue-arrow *ngIf="getDatePickerValues" direction="backward"></vue-arrow>
     <mat-icon *ngIf="!getDatePickerValues" direction="backward" class="backward_arrow" >double_arrow</mat-icon>
  </button>
  <app-date-selection-label [timescale]="timescale" [startDate]="startDate" (dateChange)="manualDateChange($event)"
    [customStyle]="customStyle" [alertSpecificData]="alertSpecificData" [getDatePickerValues]="getDatePickerValues"></app-date-selection-label>
  <button mat-button type="button" [attr.aria-label]="forwardButtonTranslationKey | translate | async"
    (click)="forwardClicked()">
    <vue-arrow *ngIf="getDatePickerValues" direction="forward"></vue-arrow>
    <mat-icon *ngIf="!getDatePickerValues" direction="forward">double_arrow</mat-icon>
  </button>
</div>