<mat-form-field [appearance]="appearance" [ngClass]="{ 'compact': compact }" >
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    vueNumberInput
    type="number"
    [id]="id"
    [min]="minimum"
    [max]="maximum"
    [ngClass]="alignText"
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required"
    [errorStateMatcher]="errorMatcher"
    [restrictNegatives]="restrictNegatives()"
    [restrictDecimals]="onlyWholeNumbers"
    [maxLength]="maxLength"
  >
  <!-- matSuffix directive cannot be content projected, using a <span> as a parent to <ng-content> as a workaround -->
  <span matSuffix>
    <ng-content select="[suffix]"></ng-content>
  </span>
  <mat-error *ngIf="errorMessage && invalid">
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
