import { formatDate } from '@angular/common';
import { PoolConsumptionStatus } from 'api/types/endpoints/getPoolConsumption';

/**
 * Determines if a pool/occurrence is editable or not based on the status
 *
 * @param pool Pool or Occurrence to check
 * @returns true if a pool/occurrence does not have the status of archived or complete
 */
export function isPoolConsumptionEditable<P extends {status: PoolConsumptionStatus}>(pool: P): boolean {
  const { status } = pool;
  return ![ 'inactive', 'completed' ].includes(status);
}

export function isPoolConsumptionEnds<P extends {endDate: string}>(pool: P): boolean {
  const { endDate } = pool;
  
  var currentDate = formatDate(
    new Date().toLocaleString(),
    'yyyy-MM-dd',
    'en-US',
    'UTC'
  );
  
  const StartDateOnly = endDate;

  if (StartDateOnly < currentDate) {
    return true;
  }

  return false;
}
