import { Component } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { Meta, Title } from '@angular/platform-browser';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { AppConfigService } from 'services/app-config.service';
import { AuthService } from 'services/authentication/auth.service';
import { CookieService } from 'services/authentication/cookie.service';
import { MenuOptions } from 'vue/components/vue-nav-drawer/vue-nav-drawer.component';
import { EnvironmentService } from './services/environment.service';

/**
 * Enable RTL testing mode via URL hash, eg: http://localhost:4200/appointments#rtl
 */
if (window.location.hash === '#rtl') {
  document.dir = 'rtl';
}

/**
 *  Main app component
 */
@Component({
  selector: 'app-root',
  styleUrls: [ './app.component.scss' ],
  templateUrl: './app.component.html'
})
export class AppComponent {
  /**
   * The top level menu options.
   */
  public sideNavMenuOptions: MenuOptions = [];

  /**
   * Name of logged in user
   */
  public userName = '';

  /**
   * Creates a new instance of the AppComponent class.
   */
  public constructor(
    private translatePipe: TranslatePipe,
    private dateAdapter: DateAdapter<MatMomentDateModule>,
    private cookieService: CookieService,
    private environmentService: EnvironmentService,
    private meta: Meta,
    private appConfigService: AppConfigService,
    private titleService: Title,
    private authService: AuthService,
  ) {}

  public ngOnInit(): void {
    const { appVersion, includeFakePortalCookies } = this.environmentService.environment();

    // Add version meta tag
    this.meta.addTag({ name: 'buildInfo', content: appVersion });

    this.setLocale();

    // Set fake portal cookies if configured
    if (includeFakePortalCookies) {
      this.setFakePortalCookies();
    }

    // Kick off the authService - Needs to be called after we set up the temp idToken
    this.authService.init();

    // Get logged in user from the JWT
    this.userName = this.authService.getUserFullName();

    // Load translation keys
    const keys: TranslationKey[] = [
      'title.allPools',
      'title.appointmentManagement',
      'title.clients',
      'title.overview',
      'title.pools',
      'title.recentActivity',
      'title.recentActivity',
      'title.templates',
      'page.title',
      'title.outages',
      'title.alerts',
      'title.configurations',
      'title.poolconsumption'
    ];

    this.translatePipe.loadTranslations(keys)
      .pipe(take(1))
      .subscribe((translations) => {
        // Set page title
        this.titleService.setTitle(`${translations[ 'page.title' ]} ${this.appConfigService.getTitleSuffix()}`);

        // Set nav options
        this.sideNavMenuOptions = [
          {
            name: translations[ 'title.appointmentManagement' ],
            route: '/appointments',
            subOptions: [
              {
                name: translations[ 'title.overview' ],
                route: '/appointments'
              },
              {
                name: translations[ 'title.templates' ],
                route: '/appointments/templates'
              },
              {
                name: translations[ 'title.recentActivity' ],
                route: '/appointments/recent-activity'
              }
            ]
          },
          {
            name: translations[ 'title.clients' ],
            route: '/clients'
          },
          {
            name: translations[ 'title.pools' ],
            route: '/pools',
            subOptions: [
              {
                name: translations[ 'title.overview' ],
                route: '/pools'
              },
              {
                name: translations[ 'title.allPools' ],
                route: '/pools/all'
              },
              {
                name: translations[ 'title.recentActivity' ],
                route: '/pools/recent-activity'
              }
            ]
          },
          {
            name: translations[ 'title.outages' ],
            route: '/outages'
          },
          // {
          //   name: translations[ 'title.alerts' ],
          //   route: '/alerts',
          //   subOptions: [
          //     {
          //       name: translations[ 'title.alerts' ],
          //       route: '/alerts'
          //     },
          //     {
          //       name: translations[ 'title.configurations' ],
          //       route: '/alerts/configurations/poolconsumption',
          //       subSubOptions: [
          //         {
          //           name: translations['title.poolconsumption'],
          //           route: '/alerts/configurations/poolconsumption'
          //         }
          //       ]
          //     },
          //   ]
          // }
        ];
      });
  }

  /**
   * Set Locale of dateAdapter & moment
   * All future instances of dateAdapter and moment will consume these locales
   * Currently the only supported locale is en-US
   */
  private setLocale(): void {
    moment.locale('en-US');
    this.dateAdapter.setLocale('en-US');
  }

  /**
   * Temporary method to set fake `idToken` and `VUE-` portal cookies.
   * This will become obsolete when integrated with Assist Portal & Pearson's domain
   */
  private setFakePortalCookies(): void {
    this.cookieService.setCookie('idToken', 'FAKE-AUTH-TOKEN');
    this.cookieService.setCookie('VUE-refreshToken', 'FAKE-REFRESH-TOKEN');
    this.cookieService.setCookie('VUE-exp', String(Date.now() + 2 * 60 * 60 * 1000)); // 2 hours from now
    this.cookieService.setCookie('VUE-logoutURL', 'https://fake-logout-url.com');
    this.cookieService.setCookie('VUE-restBaseURL', 'https://fake-rest-base-url.com');
    this.cookieService.setCookie('VUE-name', 'Homer Simpson');
    this.cookieService.setCookie('VUE-some-vue-cookie-capacity-does-not-use', 'foo');
  }
}
