<form [formGroup]="editPoolconsumptionform">
    <vue-text-input
    label="{{ 'label.alerts' | translate | async }}"
    formControlName="getDatafromAlert"
    [required]="true"
    [errorMatcher]="poolNameErrorMatcher"
    errorMessage="{{ poolNameErrorMessageKey() | translate | async }}"
  ></vue-text-input>
    <app-add-pool-consumption-searchpool  formControlName="search_pool" [patchAlertConfigData]="subscribeData"></app-add-pool-consumption-searchpool>
    <app-add-pool-consumption-threshold formControlName="Threshold" [patchAlertConfigData]="subscribeData"></app-add-pool-consumption-threshold>
    <app-add-pool-consumption-runfrequency formControlName="run_Frequency" [patchAlertConfigData]="subscribeData"></app-add-pool-consumption-runfrequency>
  </form>