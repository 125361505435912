
import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientInfo_copy } from 'api/types/endpoints/getAlertclient';
import { AllPoolsFiltersService } from 'services/all-pools-filters.service';
import { AllAlertFiltersService } from 'services/all-alert-filters.service';

@Component({
  selector: 'app-alert-select-pane',
  templateUrl: './alert-select-pane.component.html',
  styleUrls: ['./alert-select-pane.component.scss']
})
export class AlertSelectPaneComponent implements OnInit {

   /**
   * List of clients to display as options
   */
   public clientsForDisplay: ClientInfo_copy[] = [];

   /**
    * Subject that emits to array of selected clients
    */
   public clientsSelected$ = new Subject< ClientInfo_copy []>();
 
   /**
    * Subject that emits when pane should close
    */
   public closePane$ = new Subject<void>();
 
   /**
    * Subject that emits with search parameter to fetch new client list
    */
   public getNewClientList$ = new Subject<string>();
 
   /**
    * Working array of checked clients
    */
   public pendingCheckedClients: ClientInfo_copy[] = [];
 
   public constructor(private allPoolsFilterService: AllAlertFiltersService) {}
 
   public ngOnInit(): void {
     this.allPoolsFilterService.selectedClients$
       .pipe(take(1))
       .subscribe((selectedClients) => {
         // Initialize working array with already selected clients
         this.pendingCheckedClients = [ ...selectedClients ];
       });
   }
 
   /**
    * Add or remove client from working array of clients
    *
    * @param event checkbox event
    * @param clientId clientId in context
    */
   public updateChecked(event: MatCheckboxChange, clientId: string): void {
     if (!event.checked) {
       this.pendingCheckedClients = this.pendingCheckedClients.filter((c) => c.id !== clientId);
       return;
     }
     const client = this.clientsForDisplay.find((c) => c.id === clientId);
     if (client) {
       this.pendingCheckedClients.push(client);
       console.log(this.pendingCheckedClients);
       
     }
   }
 
   /**
    * Determine if client is selected
    *
    * @param clientId clientId to check
    * @returns true if client should be shown as selected
    */
   public isClientChecked(clientId: string): boolean {
     return Boolean(this.pendingCheckedClients.find((c) => c.id === clientId));
   }
 
   /**
    * Emit observable searchTerm for list of clients
    *
    * @param searchTerm search term for clients
    */
   public updateClientSearch(searchTerm: string): void {
     this.getNewClientList$.next(searchTerm);
   }
 
   /**
    * Reset working array and close pane
    */
   public cancelFilters(): void {
     this.pendingCheckedClients = [];
     this.closePane$.next();
   }
 
   /**
    * Emit working list of clients and close pane
    */
   public applyFilters(): void {
     this.clientsSelected$.next(this.pendingCheckedClients);
     this.closePane$.next();
   }
 
   /**
    * Clear all selected clients
    */
   public clearFilters(): void {
     this.pendingCheckedClients = [];
   }

}
