/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, Inject, Input } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VueFormFieldBaseComponent } from '../../utilities/vue-form-field-base/vue-form-field-base.component';

/**
 * A feature-rich number field.  Includes label and error display and control over allowed ranges.
 */
@Component({
  selector: 'vue-numeric-input',
  templateUrl: './vue-numeric-input.component.html',
  styleUrls: [ './vue-numeric-input.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: VueNumericInputComponent, multi: true
  } ]
})
export class VueNumericInputComponent extends VueFormFieldBaseComponent {
  /**
   * Minimum allowable number, as a string.  Defaults to zero.
   */
  @Input() public minimum = '0';

  /**
   * Minimum allowable number, as a string
   */
  @Input() public maximum = '';

  /**
   * Only allow integers
   */
  @Input() public onlyWholeNumbers = true;

  /**
   * An ID to apply to the <input>
   */
  @Input() public id = '';

  /**
   * Alternative compact field layout. 40px tall.
   */
  @Input() public compact = false;

  /**
   * Aligns text of input element
   *
   * @default center
   */
  @Input() public alignText: 'left' | 'center' | 'right' = 'center';

  /**
   * Maximum length of characters allowed in the input
   */
  @Input() public maxLength?: number;

  /**
   * Test cases need injected container
   *
   * @param controlContainer ControlContainer
   */
  public constructor(@Inject(ControlContainer) controlContainer: ControlContainer) {
    super(controlContainer);
  }

  public restrictNegatives(): boolean {
    return parseFloat(this.minimum) >= 0;
  }
}
