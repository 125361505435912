import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import {
  TranslatePipe,
  TranslationLookup,
} from 'pipes/translate.pipe';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { AlertsService } from 'services/api/alerts.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { PoolConsumptionPageDataService } from 'services/poolconsumption-page-data.service';
import { PoolConsumptionService } from 'services/api/poolconsumption.service';
import { Router } from '@angular/router';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { AlertEditPcToastComponent } from 'components/common/alerts/alert-edit-pc-toast/alert-edit-pc-toast.component';

/**
 *  Poolconsumption page
 */
@Component({
  selector: 'app-poolconsumption-page',
  templateUrl: './poolconsumption-page.component.html',
})
export class PoolConsumptionPageComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroyed$ = new Subject();
  private subscription!:Subscription;

  //public toastHeadingKey: TranslationKey = 'title.outageCreated';
  //public toastMessageKey: TranslationKey = 'message.addOutage.created';
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;
  // @ViewChild(AlertEditPcToastComponent) public editPoolToast!: AlertEditPcToastComponent;
  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public toastMessage = '';

  private error?: DisplayableServerError | null;

  public status: RequestStatus = 'initial';
  newPoolId: any;
  getviewConfigurationStatus: any = true;

  constructor(
    private alertsService: AlertsService,
    private translatePipe: TranslatePipe,
    private router: Router,
    private poolConsumptionService: PoolConsumptionService,
    private poolConsumptionPageDataService: PoolConsumptionPageDataService,
    private addAlertConsumptionCreate: AddAlertPoolDataService,
  ) { }

  ngOnInit(): void {
    this.subscription=this.addAlertConsumptionCreate.copyaddconsumptions$.subscribe((ele: any) => {
      if(ele != null){
      if (ele?.key != null) {
        this.getaddPoolconsumption(ele)
      }
      
    }

    })
    this.translatePipe
      .loadTranslations([
        'title.outageCreated',
        'message.addOutage.created',
        'title.outageUpdated',
        'message.editOutage.updated',
        'title.outageDeleted',
        'message.editOutage.deleted',
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });

    this.poolConsumptionPageDataService.fetchPoolConsumptionData();
  }

  ngAfterViewInit(): void {
    this.alertsService.alertsSuccess$
      .subscribe(({ alertId, type }) => {
        if (type) {
          if (type === 'new') {
            this.toast.message = this.translations['message.addOutage.created'];
            this.toast.heading = this.translations['title.outageCreated'];
          } else if (type === 'edit') {
            this.toast.message = this.translations['message.editOutage.updated'];
            this.toast.heading = this.translations['title.outageUpdated'];
          }
          else if (type === 'delete') {
            this.toast.message = this.translations['message.editOutage.deleted'];
            this.toast.heading = this.translations['title.outageDeleted'];
          }
          this.toast.open();
        }
      });

    this.alertsService.alertsDeleteError$
      .subscribe(({ error }) => {
        if (error) {
          this.status = 'error';
          this.error = error;
        }
      });
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.error;
  }

  ngOnDestroy(): void {
    // this.destrySubject()
    this.destroyed$.next();
    this.destroyed$.complete();


  }

  getaddPoolconsumption(event: any) {
    this.newPoolId = event.id ? event.id : event.key
    this.poolConsumptionService.getPoolConsumptionById({ id: event.id ? event.id : event.key }).subscribe((ele: any) => {
      this.toastMessage = ele.name;
        this.toast.open()
        this.poolConsumptionPageDataService.fetchPoolConsumptionData();
      
    })

  }

  public viewNewPool(): void {
    this.toast.close();
    this.router.navigate([`alerts/configurations/poolconsumption/${this.newPoolId}`]);
  }

  destrySubject(){
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }
}
