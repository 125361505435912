<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="Alerts | Calendar"
  navSection="{{ 'title.appointmentManagement' | translate | async }}"
  [enableBack]="enableBack"
>
</vue-page-header>
<app-page-body>
  <vue-section [loading]="pageLoading">
    <app-app-alert-resolve-chart-monthly (setIntialdata)="getselectedValue($event)"
      [metricItems]="metricItems"
      [alertSpecificData]="alertSpecificData"
      [selectedMonth]="selectedMonth"
      [getCalendarDetails]="isCalendarDetails"
    ></app-app-alert-resolve-chart-monthly>
  
  </vue-section>
</app-page-body>