<button
  [ngClass]="{'wrapper': true, 'week': isWeek, 'outside-month': isOutsideMonth, 'cursor': (!isWeek && !metricItem.isHide) }"
  (click)="openPopover(popoverDate)">
  <!-- (click)="openPopover()"> -->
  <p [ngClass]="{'date': true, 'today': isTodaysDate}" aria-hidden="true">
    <span *ngIf="!metricItem.isHide">{{ titleDisplay }}</span>
  </p>
  <p class="visually-hidden">
    <span *ngIf="!metricItem.isHide">{{ screenReaderLabel }}</span>
  </p>
  <dl class="key-value-pairs">
    <div class="pair">
      <dt><span *ngIf="!metricItem.isHide"> {{ "title.net/queue" | translate | async }}</span></dt>&nbsp;
      <dd><b><span *ngIf="!metricItem.isHide">{{this.metricItem.netQueueAva || 0 }}</span></b></dd>
    </div>

    <div class="pair">
      <dt><span *ngIf="!metricItem.isHide">{{ "title.pool/Availablility" | translate | async }}</span></dt>&nbsp;
      <dd><b><span *ngIf="!metricItem.isHide">{{this.metricItem.poolAvail || 0 }}</span></b></dd>
    </div>

    <div class="pair">
      <dt><span *ngIf="!metricItem.isHide">{{ "title.pool/utilization" | translate | async }}</span></dt>&nbsp;
      <dd><b><span *ngIf="!metricItem.isHide">{{ utilization }}</span></b></dd>
    </div>
  </dl>
</button>

<ng-template #dailyPopoverTemplate>
  <app-appt-monthly-chart-popover *ngIf="!isWeek" [date]="popoverDate"></app-appt-monthly-chart-popover>

  <app-appt-weekly-summary-popover *ngIf="isWeek" [date]="popoverDate"></app-appt-weekly-summary-popover>
</ng-template>