import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TranslatePipe, TranslationLookup } from 'pipes/translate.pipe';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { RequestStatus } from 'types/RequestStatus';
import { VueDrawerComponent } from 'vue/components/vue-drawer/vue-drawer.component';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { CapacityService } from 'services/api/capacity.service';

type Strategy = 'bulk-edit' | 'apply-capacity-template' | 'capacity-file-upload'

const defaultStrategy: Strategy = 'bulk-edit';

/**
 * Drawer containing three different ways of setting capacity
 * 1. Bulk Edit
 * 2. Applying a capacity template
 * 3. Uploading capacity file template
 */
@Component({
  selector: 'app-set-capacity-drawer',
  templateUrl: './set-capacity-drawer.component.html',
  styleUrls: [ './set-capacity-drawer.component.scss' ],
})
export class SetCapacityDrawerComponent implements OnDestroy, OnInit {
  /**
   * Reference to drawer for handling internal open/close state
   */
  @ViewChild('drawer') public drawer!: VueDrawerComponent;

  /**
   * Success Toast shown after form submission completes
   */
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  /**
   * Emitted when any type of setting capacity is successful
   */
  @Output() public success = new EventEmitter<void>();

  /**
   * Dynamic toast message that can be set for each form;
   */
  public toastMessage = '';

  /**
   * Default "strategy" for managing capacity
   */
  public currentCapacityStrategy: Strategy = defaultStrategy;

  /**
   * State of the shown form determined by currentCapacityStrategy
   */
  public formIsValid = false;

  /**
   * Loading status for the drawer
   */
  public status: RequestStatus = 'initial';

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  /**
   * Track component destroyed state
   */
  private destroyed$ = new Subject();

  /**
   * Trigger to Hit save API
   */
  public enableSaveTrigger = false;

  /**
   * Subscription for save trigger
   */
  public saveTriggerSubscription!: Subscription;

  /**
   * save Trigger Value
   */
  public saveTriggerValue: string = '';

  /**
   * seprate loading for upload capacity file
   */
  isLoading: boolean = false;

  public constructor(
    private translatePipe: TranslatePipe,
    private drawerStatusService: DrawerStatusService,
    private capacityService: CapacityService
  ) {
    drawerStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.status = status;
      });
  }

  /**
   * Load the localization translations
   */
  public ngOnInit(): void {
    this.translatePipe.loadTranslations(
      [
        'label.setCapacity.templateApplied.toast.body',
        'label.setCapacity.templateApplied.toast.heading',
        'message.changes.saved',
        'title.capacityUpdated',
        'message.capacity.uploaded',
        'title.capacityUploaded'
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });

    this.saveTriggerSubscription =
      this.capacityService.capacityUploadSaveTrigger$.subscribe(
        (value: string) => {
          this.saveTriggerValue = value;
          if (value && value === 'valid') {
            this.enableSaveTrigger = true;
          }
          if (value && value === 'uploaded') {
            this.isLoading = false;
          }
          if (value && value === 'error') {
            this.isLoading = false;
            this.enableSaveTrigger = true;
          }
        }
      );
  }

  /**
   * Clear subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Updates currentCapacityStrategy and sets form status to invalid
   *
   * @param newStrategy strategy selection that should show new form
   */
  public updateCapacityStrategy(newStrategy: Strategy): void {
    this.currentCapacityStrategy = newStrategy;
    this.formStatusChange(false);
    this.drawerStatusService.reset();
  }

  /**
   * Closes drawer
   */
  public closeDrawer(via: string): void {
    if (
      (this.saveTriggerValue === 'valid' ||
        this.saveTriggerValue === 'error') &&
      via === 'submit'
    ) {
      this.isLoading = true;
      this.capacityService.setTriggerValue('uploadFile');
    } else {
      this.drawer.close();
      this.reset();
    }
  }

  /**
   * Reset drawer to the original state
   */
  public reset(): void {
    this.updateCapacityStrategy(defaultStrategy);
    this.drawerStatusService.reset();
  }

  /**
   * Disables form save button if form is invalid
   *
   * @param isValid true if form is valid and false if not
   */
  public formStatusChange(isValid: boolean): void {
    this.formIsValid = isValid;
  }

  /**
   * Shows success toast after applying capacity template
   * And closes drawer
   *
   * @param templateName name of applied template
   */
  public appliedCapacityTemplate(templateName: string): void {
    this.toastMessage =
      this.translations[ 'label.setCapacity.templateApplied.toast.body' ].replace('{0}', templateName);
    this.toast.heading =
      this.translations[ 'label.setCapacity.templateApplied.toast.heading' ];
    this.toast.open();

    this.successfulCapacityUpdate();
  }

  /**
   * Shows success toast after submitting bulk edit
   * And closes drawer
   */
  public showBulkEditSuccess(): void {
    this.toastMessage = this.translations[ 'message.changes.saved' ];
    this.toast.heading = this.translations[ 'title.capacityUpdated' ];
    this.toast.open();

    this.successfulCapacityUpdate();
  }

  /**
   * Emits success event and optionally closes drawer (default: close drawer)
   */
  public successfulCapacityUpdate(closeDrawer = true): void {
    if (closeDrawer) {
      this.closeDrawer('');
    }

    this.success.emit();
  }

  public successfulCapacityUploaded(): void {
    this.toastMessage = this.translations['message.capacity.uploaded'];
    this.toast.heading = this.translations['title.capacityUploaded'];
    this.toast.open();

    this.successfulCapacityUpdate();
  }
}
