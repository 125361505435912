import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  TranslatePipe,
  TranslationLookup,
} from 'pipes/translate.pipe';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { AlertsService } from 'services/api/alerts.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { AlertsFiltersService } from 'services/alerts-filters.service';
import { AlertsPageDataService } from 'services/alerts-page-data.service';

/**
 *  Outages page
 */
@Component({
  selector: 'app-Alerts-page',
  templateUrl: './Alerts-page.component.html',
})
export class AlertsPageComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroyed$ = new Subject();
  //public toastHeadingKey: TranslationKey = 'title.outageCreated';
  //public toastMessageKey: TranslationKey = 'message.addOutage.created';
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;
  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public toastMessage = '';

  private error?: DisplayableServerError | null;

  public status: RequestStatus = 'initial';

  constructor(
    private alertsService: AlertsService,
    private translatePipe: TranslatePipe,
    private alertsFiltersService: AlertsFiltersService,
    private alertsPageDataService: AlertsPageDataService
  ) { }

  ngOnInit(): void {
    this.alertsPageDataService.searchAlerts();
    this.translatePipe
      .loadTranslations([
        'title.outageCreated',
        'message.addOutage.created',
        'title.outageUpdated',
        'message.editOutage.updated',
        'title.outageDeleted',
        'message.editOutage.deleted',
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  ngAfterViewInit(): void {
    this.alertsService.alertsSuccess$
      .subscribe(({ alertId, type }) => {
        if (type) {
          if (type === 'new') {
            this.toast.message = this.translations['message.addOutage.created'];
            this.toast.heading = this.translations['title.outageCreated'];
          } else if (type === 'edit') {
            this.toast.message = this.translations['message.editOutage.updated'];
            this.toast.heading = this.translations['title.outageUpdated'];
          }
          else if (type === 'delete') {
            this.toast.message = this.translations['message.editOutage.deleted'];
            this.toast.heading = this.translations['title.outageDeleted'];
          }
          this.toast.open();
        }
      });

    this.alertsService.alertsDeleteError$
      .subscribe(({ error }) => {
        if (error) {
          this.status = 'error';
          this.error = error;
        }
      });
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.error;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public resetFilter(): void {
    this.alertsFiltersService.resetFilters();
    this.searchAlerts();
  }

  public searchAlerts(): void {
    this.alertsPageDataService.searchAlerts();
  }
}
