import { Injectable } from '@angular/core';
import { CapacityMicroservice } from './microservices/capacity.microservice';

@Injectable({
  providedIn: 'root'
})
export class CalendarOccuranceService {

  constructor(private api: CapacityMicroservice) {
   }

   getCalendarCapacity(id:any,date:any){
    const path=`metrics/slotWiseCapacities/${id}?pcAlertDate=${date}`
    return this.api.get<any>(path);
   }
}
