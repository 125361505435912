import { Injectable } from '@angular/core';
import { Queue } from 'api/types';
import { AlertsStatus, GetAlertsQueryParameters } from 'api/types/endpoints/getAlerts';
import { ChipContent } from 'components/common/dismissible-chip/dismissible-chip.component';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { utc } from 'moment';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

export const ALERTS_DEFAULT_VALUES: GetAlertsQueryParameters = {
  limit: 25,
  offset: 0,
  sortBy: 'priority',
  direction: 'asc',
  status: 'In Process',
  type: 'All',
  priorities: ['All'],
  queueIds: ['All']
};

type GetAlertsKey = keyof GetAlertsQueryParameters;
export interface GetAlertsFilterChipType {
  filterKey: GetAlertsKey;
  value: GetAlertsQueryParameters[GetAlertsKey];
}

export type GetAlertsFilterChip = ChipContent<GetAlertsFilterChipType>;

@Injectable({
  providedIn: 'root'
})
export class AlertsFiltersService {
  // Init with empty values; the filter component will populate them later
  private readonly alertparams = new BehaviorSubject<GetAlertsQueryParameters>(ALERTS_DEFAULT_VALUES)

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public params$ = this.alertparams.asObservable();

  private readonly selectedFilters = new BehaviorSubject<GetAlertsFilterChip[]>([]);
  public selectedFilters$ = this.selectedFilters.asObservable();

  private readonly selectedQueues = new BehaviorSubject<Queue[]>([]);
  private readonly selectedType = new BehaviorSubject<string | null>('All');
  private readonly selectedPriorities = new BehaviorSubject<string[]>([]);
  private readonly selectedStatus = new BehaviorSubject<string>('In Process');
  private readonly selectedOpenAfterDate = new BehaviorSubject<string | null>(null);
  private readonly selectedOpenBeforeDate = new BehaviorSubject<string | null>(null);

  public selectedQueues$ = this.selectedQueues.asObservable();
  public selectedType$ = this.selectedType.asObservable();
  public selectedPriorities$ = this.selectedPriorities.asObservable();
  public selectedStatus$ = this.selectedStatus.asObservable();
  public selectedOpenAfterDate$ = this.selectedOpenAfterDate.asObservable();
  public selectedOpenBeforeDate$ = this.selectedOpenBeforeDate.asObservable();

  private translations: { [key: string]: string } = {};

  private translationKeys: TranslationKey[] = [
    'label.status',
    'label.queue',
    'label.type',
    'label.priority',
    'label.openAfter',
    'label.openBefore',
    'option.all'
  ];

  private static readonly ALL_QUEUES_CODE = 'ALL';

  public isAllPrioritySelected = false;

  public isAllQueueSelected = false;

  public constructor(translatePipe: TranslatePipe) {
    // Prevent handler from being called in the component's context
    translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;

        // Construct initial filters
        this.constructSelectedFilters(this.getNextParamsBase());
      });
    this.updateParams = this.updateParams.bind(this);
  }

  public updateParams(changes: Partial<GetAlertsQueryParameters>): void {
    this.alertparams.next({ ...this.alertparams.getValue(), offset: 0, ...changes });

    if (this.shouldUpdateSelectedFilters(changes)) {
      this.constructSelectedFilters({ ...this.alertparams.getValue(), offset: 0, ...changes });
    }
  }

  private shouldUpdateSelectedFilters(changes: Partial<GetAlertsQueryParameters>): boolean {
    return Boolean(Object.keys(changes).filter((key) => {
      return !this.ignoredKeysForSelectedFilters(key);
    }).length);
  }

  private constructSelectedFilters(params: GetAlertsQueryParameters): void {
    const selectedFilters: GetAlertsFilterChip[] = [];
    (Object.keys(params) as (keyof GetAlertsQueryParameters)[])
      .filter((key) => {
        // filter out params that shouldn't be shown as filtered
        return !this.ignoredKeysForSelectedFilters(key);
      })
      .forEach((key) => {
        // Create chip objects for respective key
        switch (key) {
          case 'status':
            selectedFilters.push(this.getStatusChipObjects(params[key]));
            break;
          case 'priorities':
            selectedFilters.push(...this.getPrioritiesChipObjects(params[key]));
            break;
          case 'type':
            selectedFilters.push(this.getTypeChipObjects(params[key]));
            break;
          case 'queueIds':
            selectedFilters.push(...this.getQueueChipObjects(params[key]));
            break;
          case 'start':
          case 'end':
            if (params[key]) {
              selectedFilters.push(this.getDateChipObject(key, params[key]));
            }
            break;
        }
      });
    this.selectedFilters.next(selectedFilters);
  }

  private ignoredKeysForSelectedFilters(key: string): boolean {
    return ['limit', 'offset', 'sortBy', 'direction', 'searchTerm'].includes(key);
  }

  private getNextParamsBase(): GetAlertsQueryParameters {
    return {
      ...this.alertparams.getValue(),
      offset: 0,
    };
  }

  private getStatusChipObjects(status: string): GetAlertsFilterChip {
    return {
      content: status,
      contentLabel: this.translations['label.status'],
      item: {
        filterKey: 'status',
        value: status
      }
    };
  }

  private getTypeChipObjects(type: string): GetAlertsFilterChip {
    return {
      content: type,
      contentLabel: this.translations['label.type'],
      item: {
        filterKey: 'type',
        value: type
      }
    };
  }

  private getPrioritiesChipObjects(priorities?: GetAlertsQueryParameters['priorities']): GetAlertsFilterChip[] {
    if (priorities?.find((e: any) => e == 'All')) {
      const priority = this.selectedPriorities.getValue().find((q) => q === 'All');

      return [{
        content: priority || 'All',
        contentLabel: this.translations['label.priority'],
        item: {
          filterKey: 'priorities',
          value: priority || 'All'
        }
      }];
    }
    else {
      return (priorities || []).map((data) => {
        const priority = this.selectedPriorities.getValue().find((q) => q === data);
        return {
          content: priority || 'All',
          contentLabel: this.translations['label.priority'],
          item: {
            filterKey: 'priorities',
            value: priority || 'All'
          }
        };
      });
    }
  }

  private getQueueChipObjects(queueIds?: GetAlertsQueryParameters['queueIds']): GetAlertsFilterChip[] {
    if (queueIds?.find((e: any) => e == 'all')) {
      const queue = this.selectedQueues.getValue().find((q) => q.id === 'all');

      return [{
        content: queue?.name || this.translations['option.all'],
        contentLabel: this.translations['label.queue'],
        item: {
          filterKey: 'queueIds',
          value: queue?.id || 'all'
        }
      }];
    }
    else {
      return (queueIds || []).map((queueId) => {
        const queue = this.selectedQueues.getValue().find((q) => q.id === queueId);
        return {
          content: queue?.name || this.translations['option.all'],
          contentLabel: this.translations['label.queue'],
          item: {
            filterKey: 'queueIds',
            value: queue?.id || 'all'
          }
        };
      });
    }
  }

  private getDateChipObject(
    key: 'start' | 'end',
    date?: GetAlertsQueryParameters['start'] | GetAlertsQueryParameters['end']
  ): GetAlertsFilterChip {
    return {
      content: date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '',
      contentLabel: key === 'start' ? this.translations['label.openAfter'] : this.translations['label.openBefore'],
      item: {
        filterKey: key,
        value: date
      }
    };
  }

  public removeStatus(): void {
    const nextParams = { ...this.alertparams.getValue(), status: 'In Process' };
    this.updateParams(nextParams);

    this.selectedStatus.next('In Process');
  }

  public removeType(): void {
    const nextParams = { ...this.alertparams.getValue(), type: 'All' };
    this.updateParams(nextParams);

    this.selectedType.next('All');
  }

  public removeQueue(queueId: string): void {
    if ((this.alertparams.getValue().queueIds || []).filter((e) => e !== queueId).length == 0) {
      this.updateParams({
        queueIds: ['all']
      });
    }
    else {
      this.updateParams({
        queueIds: (this.alertparams.getValue().queueIds || []).filter((e) => e !== queueId)
      });
    }

    if (this.selectedQueues.getValue().filter((e) => e.id !== queueId).length == 0) {
      const allQueuesOption: Queue = {
        id: 'all',
        code: AlertsFiltersService.ALL_QUEUES_CODE,
        name: this.translations['option.all']
      };

      this.selectedQueues.next([allQueuesOption]);
    }
    else {
      this.selectedQueues.next([...this.selectedQueues.getValue().filter((e) => e.id !== queueId)]);
    }
  }

  public removePriorities(priorities: string): void {
    if ((this.alertparams.getValue().priorities || ['All']).filter((e) => e !== priorities).length == 0) {
      this.updateParams({
        priorities: ['All']
      });
    }
    else {
      this.updateParams({
        priorities: (this.alertparams.getValue().priorities || ['All']).filter((e) => e !== priorities)
      });
    }

    if (this.selectedPriorities.getValue().filter((e) => e !== priorities).length == 0) {
      this.selectedPriorities.next(['All']);
    }
    else {
      this.selectedPriorities.next([...this.selectedPriorities.getValue().filter((e) => e !== priorities)]);
    }
  }

  public removeDate(type: 'start' | 'end'): void {
    const nextParams = { ...this.alertparams.getValue(), [type]: '' };
    this.updateParams(nextParams);

    if (type === 'start') {
      this.selectedOpenBeforeDate.next(null);
    } else {
      this.selectedOpenAfterDate.next(null);
    }
  }

  public updateQueueFilter(queues: any[] = []): void {
    this.selectedQueues.next(queues);
    const queueIds = queues.map((c) => c.id);
    const nextParams = { ...this.getNextParamsBase(), queueIds };
    this.alertparams.next(nextParams);

    this.constructSelectedFilters(nextParams);
  }

  public updateTypeFilter(type: string): void {
    this.selectedType.next(type);
    const nextParams = { ...this.getNextParamsBase(), type };
    this.alertparams.next(nextParams);

    this.constructSelectedFilters(nextParams);
  }

  public updatePrioritiesFilter(priorities: [] = []): void {
    this.selectedPriorities.next(priorities);
    const nextParams = { ...this.getNextParamsBase(), priorities };
    this.alertparams.next(nextParams);

    this.constructSelectedFilters(nextParams);
  }

  public updateStatusFilter(status: AlertsStatus): void {
    this.selectedStatus.next(status);
    const nextParams = { ...this.getNextParamsBase(), status };
    this.alertparams.next(nextParams);

    this.constructSelectedFilters(nextParams);
  }

  public updateDateRangeFilter(beforeDate: string, afterDate: string): void {
    this.selectedOpenAfterDate.next(afterDate || null);
    this.selectedOpenBeforeDate.next(beforeDate || null);

    const nextParams = {
      ...this.getNextParamsBase(), start: beforeDate,
      end: afterDate
    };
    this.alertparams.next(nextParams);

    this.constructSelectedFilters(nextParams);
  }

  public resetFilters() {
    const allQueuesOption: Queue = {
      id: 'all',
      code: AlertsFiltersService.ALL_QUEUES_CODE,
      name: this.translations['option.all']
    };

    this.selectedQueues.next([allQueuesOption]);
    this.selectedOpenAfterDate.next(null);
    this.selectedOpenBeforeDate.next(null);
    this.selectedType.next('All');
    this.selectedPriorities.next(['All']);
    this.selectedStatus.next('In Process');

    const nextParams = {
      ...this.getNextParamsBase(),
      queueIds: ['all'],
      priorities: ['All'],
      type: 'All',
      status: 'In Process',
      start: '',
      end: ''
    };

    this.alertparams.next(nextParams);
    this.constructSelectedFilters(nextParams);
  }

  public updateDefaultQueueFilter(queues: Queue[] = []): void {
    this.selectedQueues.next(queues);
  }

  public updateDefaultPriorityFilter(priority: string[] = []): void {
    this.selectedPriorities.next(priority);
  }
}
