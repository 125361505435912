import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddPoolState, PoolStep } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { AllAlertFiltersService } from 'services/all-alert-filters.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { VueDrawerComponent, VueDrawerConfig } from 'vue/components/vue-drawer/vue-drawer.component';

@Component({
  selector: 'app-add-pool-consumption-drawer',
  templateUrl: './add-pool-consumption-drawer.component.html',
  styleUrls: ['./add-pool-consumption-drawer.component.scss']
})

export class AddPoolConsumptionDrawerComponent implements OnInit {
  @Output() public success = new EventEmitter<any>();
  @Input() patchDataStatus: any;
  addPoolForm!: FormGroup;
  getDataForCreateAlert: any = null;
  disabledAtresponsegetting: any = false;
  public step: PoolStep = 'form';
  public drawerConfig: VueDrawerConfig = {
    disableClose: true,
  };
  private error?: DisplayableServerError | null;
  public status: RequestStatus = 'initial';
  @ViewChild('drawer') public drawer!: VueDrawerComponent;
  errorMessage: any;
  childupdatedValuestatus: any = false;
  private destroyed$ = new Subject();
  getFlagfromobserver: any = false;
  constructor(private addPoolState: AddAlertPoolDataService, private addPoolStates: AddPoolState,
    private drawerStatusService: DrawerStatusService,
    private unsavedChangesDialogService: UnsavedChangesDialogService,
    private addAlertConsumptionCreate: AddAlertPoolDataService,
    private allPoolsFilterService: AllAlertFiltersService,
  ) { }

  ngOnInit(): void {
    this.addAlertConsumptionCreate.gettableFilterUpdate$.subscribe((ele: any) => {
      this.getFlagfromobserver = ele;
    });
    this.drawerStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.status = status;
      });

    this.addAlertConsumptionCreate.errorobjects$.subscribe((ele: any) => {
      // console.log(ele);
      if (ele) {
        this.status = 'error';
        this.error = { message: ` Time: ${ele.runTime}(UTC) - Day: ${ele.Element}`, title: ` RunFrequency Duplicate are not allowed` || '' };
        this.drawerStatusService.error();
      }
    })
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.addPoolStates.displayableServerError || this.error;
  }

  public closeDrawerbtn(): void {
    if (this.childupdatedValuestatus) {
      this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
        if (choseToLeave) {
          this.getDataForCreateAlert = null;
          this.closeDrawer();
          if (this.getFlagfromobserver) {
            this.allPoolsFilterService.addClientsToFilter([]);
            this.allPoolsFilterService.updateMoreFilters();
          }
        }
      });
    }
    else {
      this.closeDrawer();
      if (this.getFlagfromobserver) {
        this.allPoolsFilterService.addClientsToFilter([]);
        this.allPoolsFilterService.updateMoreFilters();
      }
    }
  }

  public closeDrawer(): void {
    this.drawer.close();
    this.reset();
  }

  public reset(): void {
    this.addPoolState.resetState();
    this.drawerStatusService.reset();
  }

  getdatafromchild(event: any) {
    this.getDataForCreateAlert = event;
  }

  SavePool() {
    this.drawerStatusService.loading();
    this.errorMessage = null;
    this.disabledAtresponsegetting = true;
    let checkValidationerror = this.getfilterValue(this.getDataForCreateAlert);
    if (checkValidationerror.length == 0) {
      this.addAlertConsumptionCreate.createAlertConsumption(this.getDataForCreateAlert).subscribe((response) => {
        if (response.id) {
          this.success.emit(response);
          this.closeDrawer();
          this.error = null;
          this.drawerStatusService.success();
          if (this.getFlagfromobserver) {
            this.allPoolsFilterService.addClientsToFilter([]);
            this.allPoolsFilterService.updateMoreFilters();
          }
        }
      }, (error: unknown) => {
        this.disabledAtresponsegetting = false;
        this.error = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
    }
  }

  getStatusofchild(event: any) {
    this.drawerStatusService.reset();
    this.childupdatedValuestatus = event;
  }

  cancelDrawer() {
    if (this.childupdatedValuestatus) {
      this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
        if (choseToLeave) {
          this.getDataForCreateAlert = null;
          this.closeDrawer();
        }
      });
    }
    else {
      this.closeDrawer();
    }
  }



  getrunfrequencyupdated(value: any) {
    const idMap = new Map();
    const duplicates: any = [];
    for (const obj of value) {
      const { runTime, runDaysOfWeek, isCritical, runPriority } = obj;
      if (idMap.has(runTime)) {
        const existingArray = idMap.get(runTime);
        if (existingArray.isCritical === isCritical && existingArray.runPriority === runPriority) {
          const currentDuplicates = [];
          for (const element of runDaysOfWeek) {
            if (existingArray.runDaysOfWeek.indexOf(element) !== -1) {
              currentDuplicates.push(element);
              console.log(`Duplicate element ${element} found for ID ${runTime}`);
              this.addAlertConsumptionCreate.getupdatedlist({ 'Element': element, 'runTime': runTime });
            }
          }
          if (currentDuplicates.length > 0) {
            duplicates.push({ runTime, isCritical, runPriority, duplicates });
          }
          existingArray.runDaysOfWeek = [...new Set([...existingArray.runDaysOfWeek, ...runDaysOfWeek])];
        }

      } else {
        idMap.set(runTime, { runDaysOfWeek, isCritical, runPriority });
      }}
    return duplicates;
  }

  getfilterValue(filter: any) {
    let value: any = [];
    filter.run_Frequency.FormGroup.Time.forEach((ele: any) => {
      let arraylist: any = [];
      ele.weekDays.forEach((data: any) => {
        if (data.checked == true)
          arraylist.push(data.value);
      })
      value.push(
        {
          "runTime": ele.Time,
          "runPriority": ele.Priority,
          "isCritical": ele.Critical,
          "daysFlag": arraylist.length == 7 ? "daily" : "weekly",
          "runDaysOfWeek": arraylist
        })
    });
    const result = this.getrunfrequencyupdated(value);
    return result;
  }
}
